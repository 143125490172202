import { Route } from "@angular/router";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { AppShellComponent, AuthGuard } from "@vendure/admin-ui/core";

import { extensionRoutes } from "./extension.routes";

export const routes: Route[] = [
  {
    path: "login",
    loadChildren: () =>
      import("@vendure/admin-ui/login").then((m) => m.LoginModule),
  },
  {
    path: "",
    canActivate: [AuthGuard],
    component: AppShellComponent,
    data: {
      breadcrumb: _("breadcrumb.dashboard"),
    },
    children: [
      {
        path: "",
        pathMatch: "full",
        loadChildren: () =>
          import("@vendure/admin-ui/dashboard").then((m) => m.DashboardModule),
      },
      {
        path: "catalog",
        loadChildren: () =>
          import("@vendure/admin-ui/catalog").then((m) => m.CatalogModule),
      },
      {
        path: "customer",
        loadChildren: () =>
          import("@vendure/admin-ui/customer").then((m) => m.CustomerModule),
      },
      {
        path: "orders",
        loadChildren: () =>
          import(
            "./extensions/a27108ecc8e72c2309349114d5eecd4a1e72bc1e241031182a6f54c36dd416f8/orders.module"
          ).then((m) => m.OrderModule),
      },
      {
        path: "marketing",
        loadChildren: () =>
          import("@vendure/admin-ui/marketing").then((m) => m.MarketingModule),
      },
      {
        path: "settings",
        loadChildren: () =>
          import("@vendure/admin-ui/settings").then((m) => m.SettingsModule),
      },
      {
        path: "system",
        loadChildren: () =>
          import("@vendure/admin-ui/system").then((m) => m.SystemModule),
      },
      ...extensionRoutes,
    ],
  },
];
